export default function setUserProperties(properties: Record<string, any>) {
  if (window.amplitude) {
    const identifyEvent = new window.amplitude.Identify();

    Object.keys(properties).forEach((key: string) => {
      identifyEvent.set(key, properties[key]);
    });

    window.amplitude.identify(identifyEvent);
  }
}
